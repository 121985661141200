<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <!-- 화학자재 목록 -->
    <c-table
      ref="grid"
      title="LBL0010357"
      tableId="grid"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hsl-manage',
  data() {
    return {
      searchParam: {
        plantCd: null,
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'plantName' },
          { index: 1, colName: 'materialName' },
          { index: 5, colName: 'materialName' },
          { index: 6, colName: 'materialName' },
          { index: 7, colName: 'materialName' },
          { index: 8, colName: 'materialName' },
          { index: 9, colName: 'materialName' },
          { index: 10, colName: 'materialName' },
          { index: 11, colName: 'materialName' },
          { index: 12, colName: 'materialName' },
          { index: 13, colName: 'materialName' },
          { index: 14, colName: 'materialName' },
          { index: 15, colName: 'materialName' },
          { index: 16, colName: 'materialName' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장명
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:120px',
            sortable: true,
            fix: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            // 화학자재명
            label: 'LBL0010358',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
            fix: true,
          },
          {
            name: 'chemNmEn',
            field: 'chemNmEn',
            // 화학물질명
            label: 'LBL0010359',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
            fix: true,
          },
          {
            name: 'casNo',
            field: 'casNo',
            // CAS No.
            label: 'LBL0010360',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            fix: true,
          },
          {
            name: 'molecularFormula',
            field: 'molecularFormula',
            // 분자식
            label: 'LBL0010361',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            // 폭발한계(%)
            label: 'LBL0010362',
            align: 'center',
            child: [
              {
                name: 'explUpLimit',
                field: 'explUpLimit',
                // 상한
                label: 'LBL0010363',
                align: 'center',
                style: 'width:70px',
                sortable: false
              },
              {
                name: 'explLowLimit',
                field: 'explLowLimit',
                // 하한
                label: 'LBL0010364',
                align: 'center',
                style: 'width:70px',
                sortable: false
              },
            ]
          },
          {
            name: 'exposureLimit',
            field: 'exposureLimit',
            // 노출기준
            label: 'LBL0010365',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'toxicValue',
            field: 'toxicValue',
            // 독성치
            label: 'LBL0010366',
            align: 'center',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'flashingPoint',
            field: 'flashingPoint',
            // 인화점
            label: 'LBL0010367',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'ignitionPoint',
            field: 'ignitionPoint',
            // 발화점
            label: 'LBL0010368',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'vaporPressure',
            field: 'vaporPressure',
            // 증기압
            label: 'LBL0010369',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'corrosivenessFlag',
            field: 'corrosivenessFlag',
            // 부식성유무
            label: 'LBL0010370',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'extraReaction',
            field: 'extraReaction',
            // 이상반응유무
            label: 'LBL0010371',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'dailyUsage',
            field: 'dailyUsage',
            // 일일취급량
            label: 'LBL0010372',
            align: 'center',
            style: 'width:100px',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'stockage',
            field: 'stockage',
            // 저장량
            label: 'LBL0010373',
            align: 'center',
            style: 'width:100px',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            // 비고
            label: 'LBLREMARK',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.psi.hsl.hazardmaterials.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row, col) {
      this.openChemMaterialPop(row, col.name);
    },
    openChemMaterialPop(row, _name) {
      let tabname = '';
      if (_name == 'materialName') {
        tabname = 'chemPsm';
      } else if (_name == 'chemNmKr') {
        tabname = 'chemMsds';
      }
      this.popupOptions.title = 'LBL0010374';
      this.popupOptions.param = {
        mdmChemMaterialId: row ? row.mdmChemMaterialId : '',
        plantCd: row ? row.plantCd : '',
        tabName: tabname,
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chemDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
